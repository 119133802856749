import classNames from "classnames";

function Item({name, link, level = 1}) {
    const classes = classNames(
        'p-1 border-s-2 border-transparent hover:text-bright-elephant ' +
        'hover:border-bright-elephant',
        {
            'extra-large' : level === 1,
            'large' : level === 2,
        }
    );

    return (
        <li className={classes}>
            <a href={`#${link}`} className="ms-1 w-full inline-block">{name}</a>
        </li>
    )
}

export default Item;