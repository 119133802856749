function PagTabButton ({ name, bg }) {
    const expand = false;

    return (
        <button className="relative grid grid-cols-[3fr_1fr] border-s-0 w-60 text-start transition-shadow duration-200 rounded-e-md rounded-y-md border
        border-black z-20 bg-white hover:w-64 hover:shadow-[0px_8px_0px_0px_rgba(0,0,0)]"
        >
        <span className="py-2 px-4">{ name }</span>

        <span className={`border-s border-black rounded-e-[5px] py-2 px-5 m-0 ${bg}`}>&nbsp;</span>
    </button>
    )
}

export default PagTabButton;