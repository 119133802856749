import React, { Component } from 'react';
import FileTabButton from "../file-tab/FileTabButton";

function FileTabs() {
    return (
        <div id="tabs.file_tabs" className="mt-6">
            <h3>File Tabs</h3>

            <div className="my-6 md:flex">
                <div className="flex md:flex-row flex-col border-b border-bright-elephant">
                    <FileTabButton active name="Starters" icon="fa-solid fa-shrimp" />
                    <FileTabButton name="Mains" icon="fa-solid fa-burger" />
                    <FileTabButton name="Desserts" icon="fa-solid fa-cake-candles" />
                    <FileTabButton name="Drinks" icon="fa-solid fa-mug-saucer" />
                </div>
            </div>
        </div>
    )
}

export default FileTabs;
