import classNames from "classnames";

function FileTabButton ({ name, icon, active = false}) {
    const classes = classNames(
        'rounded-t-md p-2 me-2 hover:bg-bright-elephant/25',
        {
            'bg-bright-elephant/75 text-white': active,
            'text-soft-black': !active
        }
    );

    return (
        <button
            className={`${classes}`}
        >
            <i className={`${icon} fa-xs me-2`}></i>

            { name }
        </button>
    )
}

export default FileTabButton;